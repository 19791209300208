import { Ref } from "react";
import { Grid, Typography } from "@mui/material";
import FieldFW from "../../templates/forms/FieldFW";
import { Formik, Form } from "formik";
import { validationSchema } from "./validationSchema";
import userService from "../../../../services/userService";
import useStyles from "./styles";
import { useMutation } from "react-query";
import { extractErrorMessage } from "../../../../utils/utils";


export type UserInfoType = {
  username: string;
};

export interface UserState {
  partnerAlias?: string;
}
export interface UserFormType {
  manager_email: string;
}
interface Props {
  setUser: (user: UserInfoType) => void;
  userState?: UserState;
  handleNext?: () => void;
  innerRef?: Ref<any>;
  setErrorMsg: (s: string) => void;
}

export default function UserInfo(props: Props) {
  const classes = useStyles();
  const { setUser, setErrorMsg } = props;
  const { mutateAsync: verifyCanAssignRole } = useMutation(userService.verifyCanAssignRole);

  return (
    <Formik
      innerRef={props.innerRef}
      validateOnChange={true}
      initialValues={{
        manager_email: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (data: UserFormType, { setSubmitting }) => {
        setSubmitting(true);
        const username = data.manager_email!
        try{
          await verifyCanAssignRole(username.toLowerCase())
        } catch (err:any){
          setErrorMsg(extractErrorMessage(err))
          setSubmitting(false);
          return;
        }
        let user: UserInfoType = {
          username: username,
        };
        setUser(user);
        setSubmitting(false);
        if (props.handleNext) {
          props.handleNext();
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Typography variant="h6" className={classes.title}>
              Manager
            </Typography>
            <FieldFW
              type="text"
              required
              id="manager_email"
              name="manager_email"
              placeholder="Email"
              variant="outlined"
            />
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
