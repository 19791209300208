/**
 * @file AddOPCO.tsx
 * @description AddOPCO component
 * @author Harry Rhodes
 * @exports React.Component
 */
import { useState } from "react";
import { OPCOCreateType } from "../../../services/opcoService";
import { UserInfoType } from "../../common/onboarding/UserInfo";
import OPCOInfo from "./OPCOInfo";
import UserInfo from "../../common/onboarding/UserInfo";
import Review from "./Review";
import StepperWrapper, { StepperContentType } from "../../common/onboarding/StepperWrapper";
import { Dialog, AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { Transition } from "../../common/Transition";
import useStyles from "./styles";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  createOPCO: (opco: FormData) => Promise<any>;
  open: boolean;
  setOpen: (state: boolean) => void;
  setErrorMsg: (s: string) => void;
}
/**
 * Renders AddOPCO component
 * @param props component props @see Props
 * @returns {React.Component} AddOPCO component
 */
export default function AddOPCO(props: Props) {
  const classes = useStyles();
  const { createOPCO, open, setOpen, setErrorMsg } = props;
  const [opco, setOPCO] = useState<OPCOCreateType>();
  const [target, setTarget] = useState<File>();
  const [user, setUser] = useState<UserInfoType>();

  const stepperData: StepperContentType[] = [
    {
      id: 1,
      name: "OPCO Info",
      content: <OPCOInfo setOPCO={setOPCO} setTarget={setTarget} />,
      optional: false,
    },
    {
      id: 2,
      name: "Assign OPCO Admin",
      content: (
        <UserInfo 
          setUser={setUser} 
          setErrorMsg={setErrorMsg}
        />
      ),
      optional: false,
    },
    {
      id: 3,
      name: "Review",
      content: (
        <Review
          opco={opco as OPCOCreateType}
          user={user as UserInfoType}
          target={target as File}
          createOPCO={createOPCO}
          setErrorMsg={setErrorMsg}
        />
      ),
      optional: false,
    },
  ];

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => setOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="secondary"
            onClick={() => setOpen(false)}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.title}
          >
            OPCO Designer
          </Typography>
        </Toolbar>
      </AppBar>
      <StepperWrapper data={stepperData} />
    </Dialog>
  );
}
