/**
 * @file Review.tsx
 * @description Review component for AddPartner
 * @author Harry Rhodes
 * @exports React.Component
 */
import { Ref, useState, useEffect } from "react";
import { List, ListItem, ListItemText, Grid, Typography } from "@mui/material";
import { PartnerCreateType } from "../../../../services/partnerService";
import { UserInfoType } from "../../../common/onboarding/UserInfo";
import { Formik, Form } from "formik";
import useStyles from "./styles";
import { extractErrorMessage } from "../../../../utils/utils";
import userService, { UserRole, UserCreateType } from "../../../../services/userService";
import { useMutation, useQueryClient } from "react-query";
import { invalidateUserQueries } from "../../../../utils/invalidateQueries";


interface Props {
  partner: PartnerCreateType;
  target: File;
  user: UserInfoType;
  createPartner: (partner: FormData) => Promise<any>;
  setErrorMsg: (s: string) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}
export default function Review(props: Props) {
  const classes = useStyles();
  const { partner, target, user, createPartner, setErrorMsg } = props;
  const [preview, setPreview] = useState("");

  const queryClient = useQueryClient();
  const { mutateAsync: createUser } = useMutation(userService.create, {
    onSuccess: () => {
      invalidateUserQueries(queryClient);
    }
  });


  useEffect(() => {
    if (target) {
      const reader = new FileReader();
      reader.readAsDataURL(target);
      reader.addEventListener("load", () => {
        setPreview(reader.result as string);
      });
    }
  }, [target]);

  return (
    <Formik
      innerRef={props.innerRef}
      initialValues={{
        null: null,
      }}
      onSubmit={async () => {
        //Parse into form data for potential file upload
        let formData = new FormData();
        formData.append("name", partner.name);
        formData.append("alias", partner.alias.toUpperCase());
        formData.append("pai_contingent", partner.pai_contingent);
        formData.append("opco_ids", JSON.stringify(partner.opco_ids));
        if (target) {
          formData.append("file", target);
        }

        let userData:UserCreateType = {
          email: user.username.toLowerCase(),
          role: UserRole.PARTNER_MANAGER,
          partner_alias: partner.alias.toUpperCase()
        }
        
        try {
          await createPartner(formData)
          await createUser(userData);
        } catch (err: any) {
          setErrorMsg(extractErrorMessage(err));
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                New Partner Details
              </Typography>
              {preview && <img className={classes.logo} src={preview} alt="logo preview" />}
              <div>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.name}
                      secondary={"Name"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.alias}
                      secondary={"Alias"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.partner.pai_contingent}
                      secondary={"PAI Contingent"}
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Partner Manager Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={props.user.username}
                    secondary={"Email"}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
