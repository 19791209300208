import { Ref, useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Grid,
  Typography
} from "@mui/material";
import { BrandCreateType } from "../../../../services/brandService";
import { Formik, Form } from "formik";
import useStyles from "./styles";
import { UserInfoType } from "../../../common/onboarding/UserInfo";
import { extractErrorMessage } from "../../../../utils/utils";
import { isAntiSpoofingFeatureEnabled } from "../../../../utils/featureToggle";
import userService, { UserCreateType, UserRole, } from "../../../../services/userService";
import { useMutation, useQueryClient } from "react-query";
import { invalidateUserQueries } from "../../../../utils/invalidateQueries";

interface Props {
  partnerAlias: string;
  brand: BrandCreateType;
  target: File;
  user: UserInfoType;
  createBrand: (brand: FormData) => Promise<any>;
  setErrorMsg: (s: string) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}
export default function Review(props: Props) {
  const classes = useStyles();
  const {partnerAlias, brand, target, user, createBrand, setErrorMsg } = props;
  const [preview, setPreview] = useState("");

  const queryClient = useQueryClient();
  const { mutateAsync: createUser } = useMutation(userService.create, {
    onSuccess: () => {
      invalidateUserQueries(queryClient);
    }
  });


  useEffect(() => {
    if (target) {
      const reader = new FileReader();
      reader.readAsDataURL(target);
      reader.addEventListener("load", () => {
        setPreview(reader.result as string);
      });
    }
  }, [target]);

  return (
    <Formik
      innerRef={props.innerRef}
      initialValues={{
        null: null,
      }}
      onSubmit={async () => {
        //Parse into form data for potential file upload
        let formData = new FormData();
        formData.append("name", brand.name);
        formData.append("desc", brand.desc);
        formData.append("alias", brand.alias.toUpperCase());
        formData.append("signature", brand.signature);
        formData.append("partner_id", brand.partner_id.toString());
        formData.append("feature_anti_spoofing_enabled", String(brand.feature_anti_spoofing_enabled));
        formData.append("pai_contingent", brand.pai_contingent);
        if (target) {
          formData.append("file", target);
        }

        let userData:UserCreateType = {
          email: user.username.toLowerCase(),
          role: UserRole.BRAND_MANAGER,
          brand_alias: brand.alias.toUpperCase(),
          partner_alias: partnerAlias
        }
        
        try {
          await createBrand(formData)
          await createUser(userData);
        } catch (err: any) {
          setErrorMsg(extractErrorMessage(err));
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                New Brand Details
              </Typography>
              {preview && <img className={classes.logo} src={preview} alt="logo preview" />}
              <div>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={props.brand.name}
                      secondary={"Name"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.brand.desc}
                      secondary={"Description"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.brand.alias}
                      secondary={"Alias"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.brand.pai_contingent}
                      secondary={"PAI Contingent"}
                    />
                  </ListItem>
                  {isAntiSpoofingFeatureEnabled() &&
                    <ListItem>
                      <ListItemText
                        primary={props.brand.feature_anti_spoofing_enabled ? "Enabled" : "Disabled"}
                        secondary={"Anti-Spoofing feature"}
                      />
                    </ListItem>
                  }
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                Brand Manager Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={props.user.username}
                    secondary={"Email"}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
