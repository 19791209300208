/**
 * @file validationSchema.ts
 * @description Yup validation schema for UserInfo
 * @author Harry Rhodes
 * @exports Yup.object
 */
import * as Yup from "yup";

/* eslint-disable no-template-curly-in-string */

export const validationSchema = Yup.object().shape({
  manager_email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email address")
    .min(5, "Email address cannot be shorter than ${min} characters")
    .max(100, "Email address cannot be longer than ${max} characters"),
});
