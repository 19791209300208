import { Ref, useState, useEffect } from "react";
import { List, ListItem, ListItemText, Grid, Typography } from "@mui/material";
import { OPCOCreateType } from "../../../../services/opcoService";
import { Formik, Form } from "formik";
import useStyles from "./styles";
import { UserInfoType } from "../../../common/onboarding/UserInfo";
import { extractErrorMessage } from "../../../../utils/utils";
import userService, { UserRole, UserCreateType } from "../../../../services/userService";
import { useMutation, useQueryClient } from "react-query";
import { invalidateUserQueries } from "../../../../utils/invalidateQueries";

interface Props {
  opco: OPCOCreateType;
  target: File;
  user: UserInfoType;
  createOPCO: (opco: FormData) => Promise<any>;
  setErrorMsg: (s: string) => void;
  handleNext?: () => void;
  innerRef?: Ref<any>;
}
export default function Review(props: Props) {
  const classes = useStyles();
  const { opco, target, user, createOPCO, setErrorMsg } = props;
  const [preview, setPreview] = useState("");

  const queryClient = useQueryClient();
  const { mutateAsync: createUser } = useMutation(userService.create, {
    onSuccess: () => {
      invalidateUserQueries(queryClient);
    }
  });


  useEffect(() => {
    if (target) {
      const reader = new FileReader();
      reader.readAsDataURL(target);
      reader.addEventListener("load", () => {
        setPreview(reader.result as string);
      });
    }
  }, [target]);
  return (
    <Formik
      innerRef={props.innerRef}
      initialValues={{
        null: null,
      }}
      onSubmit={async () => {
        //Parse into form data for potential file upload
        let formData = new FormData();
        formData.append("name", opco.name);
        formData.append("alias", opco.alias.toUpperCase());
        formData.append("market", opco.market);
        if (target) {
          formData.append("file", target);
        }

        let userData:UserCreateType = {
          email: user.username.toLowerCase(),
          role: UserRole.OPCO_ADMIN,
          opco_alias: opco.alias.toUpperCase()
        }
        
        try {
          await createOPCO(formData)
          await createUser(userData);
        } catch (err: any) {
          setErrorMsg(extractErrorMessage(err));
        }
      }}
    >
      <Form>
        <div className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                New OPCO Details
              </Typography>
              {preview && <img className={classes.logo} src={preview} alt="logo preview" />}
              <div>
                <List>
                  <ListItem>
                    <ListItemText
                      primary={props.opco.name}
                      secondary={"Name"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.opco.alias}
                      secondary={"Alias"}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary={props.opco.market}
                      secondary={"Market"}
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" className={classes.title}>
                OPCO Admin Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemText
                    primary={props.user.username}
                    secondary={"Email"}
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </div>
      </Form>
    </Formik>
  );
}
